import { type ReactElement } from "react";

import {
  type CheckrApplicationStatus,
  CheckrReportStatus,
  DocumentRequirementType,
} from "../Documents/types";
import { AccountsDocumentPendingIcon } from "../statusIcons";

interface CheckrRequirementStatusParams {
  requirementType: DocumentRequirementType;
  checkrApplicationStatus: CheckrApplicationStatus;
  isCheckrRequirementAlmostExpired: boolean;
}

export interface GetCheckrRequirementStatus {
  description?: string;
  linkText?: string;
  icon?: ReactElement;
}

export function getCheckrRequirementStatus(
  params: CheckrRequirementStatusParams
): GetCheckrRequirementStatus | undefined {
  const { requirementType, checkrApplicationStatus, isCheckrRequirementAlmostExpired } = params;

  if (
    checkrApplicationStatus.reportStatus === CheckrReportStatus.PENDING ||
    requirementType === DocumentRequirementType.PENDING
  ) {
    return {
      description: "Clipboard is waiting for your results.",
      icon: <AccountsDocumentPendingIcon />,
    };
  }

  if (requirementType === DocumentRequirementType.EXPIRED) {
    return {
      description: "Document Expired!",
      linkText: "Click here to start a new background check with Checkr.",
    };
  }

  if (requirementType === DocumentRequirementType.REJECTED) {
    return {
      description: "Document Rejected!",
      linkText: "Tap to learn more.",
    };
  }

  if (requirementType === DocumentRequirementType.COMPLETED) {
    if (isCheckrRequirementAlmostExpired) {
      return {
        description: "Your background check is expiring soon.",
        linkText: "Click here to start a new background check with Checkr.",
      };
    }

    return undefined;
  }

  return { linkText: "Click here to start your background check with Checkr." };
}
