import { isNil } from "@clipboard-health/util-ts";
import { type OpenShiftCountResponse } from "@src/appV2/OpenShifts/api/useOpenShiftCount";

import { type HcpRequirement } from "../Documents/types";

interface Props {
  openShiftCountData: OpenShiftCountResponse;
  allRequirementsMissingAtHcfLevel: HcpRequirement[];
  qualification?: string;
}

interface RequirementResult {
  potentialShiftsCount: number;
  potentialFacilitiesCount: number;
}

export function getPotentialShiftsAndFacilitiesForRequirements(
  props: Props
): Map<string, RequirementResult> {
  const { openShiftCountData, allRequirementsMissingAtHcfLevel, qualification } = props;

  const slots = Object.values(openShiftCountData).flatMap((openShiftDay) => openShiftDay.slots);

  const facilityShiftCounts = new Map<string, number>();
  for (const slot of slots) {
    facilityShiftCounts.set(slot.facilityId, (facilityShiftCounts.get(slot.facilityId) ?? 0) + 1);
  }

  const results = new Map<string, RequirementResult>();

  for (const requirement of allRequirementsMissingAtHcfLevel) {
    let potentialShiftsCount = 0;
    const potentialFacilities = new Set<string>();

    facilityShiftCounts.forEach((shiftCount, facilityId) => {
      if (
        requirement.requiredBy?.includes(facilityId) &&
        (isNil(qualification) ||
          isNil(requirement.hcpType) ||
          requirement.hcpType.includes(qualification))
      ) {
        potentialShiftsCount += shiftCount;
        potentialFacilities.add(facilityId);
      }
    });

    if (results.has(requirement.reqId)) {
      results.set(requirement.reqId, {
        potentialShiftsCount:
          results.get(requirement.reqId)!.potentialShiftsCount + potentialShiftsCount,
        potentialFacilitiesCount:
          results.get(requirement.reqId)!.potentialFacilitiesCount + potentialFacilities.size,
      });
    } else {
      results.set(requirement.reqId, {
        potentialShiftsCount,
        potentialFacilitiesCount: potentialFacilities.size,
      });
    }
  }

  return results;
}
