import { isDefined } from "@clipboard-health/util-ts";
import { differenceInDays, parseISO } from "date-fns";
import { uniq } from "lodash";

import { CHECKR_REQUIREMENT_ID } from "../Documents/constants";
import {
  type CheckrApplicationStatus,
  CheckrInvitationStatus,
  type HcpDocument,
  type HcpRequirementStatus,
} from "../Documents/types";

export function updateStatusForCheckrRequirements(params: {
  hcpRequirementStatus: HcpRequirementStatus;
  checkrApplicationStatus: CheckrApplicationStatus;
  rejectedDocuments: HcpDocument[];
}): HcpRequirementStatus {
  const { hcpRequirementStatus, checkrApplicationStatus, rejectedDocuments } = params;

  const updatedHcpRequirementStatus = { ...hcpRequirementStatus };

  const isCheckrDocumentRejected = isDefined(rejectedDocuments[0]);

  const isCheckrDocumentNotRejectedByCbhInRejectedRequirements =
    !isCheckrDocumentRejected &&
    updatedHcpRequirementStatus?.rejected?.includes(CHECKR_REQUIREMENT_ID);

  if (isCheckrDocumentNotRejectedByCbhInRejectedRequirements) {
    updatedHcpRequirementStatus.missing.push(CHECKR_REQUIREMENT_ID);
    updatedHcpRequirementStatus.rejected = updatedHcpRequirementStatus?.rejected?.filter(
      (requirementId) => requirementId !== CHECKR_REQUIREMENT_ID
    );
  }

  const isCheckrDocumentPendingCBHProcessing =
    !updatedHcpRequirementStatus.completed.includes(CHECKR_REQUIREMENT_ID) &&
    !updatedHcpRequirementStatus.expired.includes(CHECKR_REQUIREMENT_ID) &&
    !updatedHcpRequirementStatus.rejected.includes(CHECKR_REQUIREMENT_ID);

  const isCheckrDocumentPendingCBHProcessingInMissingRequirements =
    checkrApplicationStatus?.invitationStatus === CheckrInvitationStatus.COMPLETED &&
    isDefined(checkrApplicationStatus?.reportStatus) &&
    checkrApplicationStatus?.reportStatus !== "" &&
    isCheckrDocumentPendingCBHProcessing;

  if (isCheckrDocumentPendingCBHProcessingInMissingRequirements) {
    updatedHcpRequirementStatus.missing = updatedHcpRequirementStatus.missing.filter(
      (requirementId) => requirementId !== CHECKR_REQUIREMENT_ID
    );
    updatedHcpRequirementStatus.pending = [
      CHECKR_REQUIREMENT_ID,
      ...updatedHcpRequirementStatus.pending,
    ];
  }

  const checkrDocument = rejectedDocuments[0];

  const checkrRequirementExpiryDate =
    updatedHcpRequirementStatus.requirements[CHECKR_REQUIREMENT_ID]?.expiry;

  const isCheckrDocumentRejectedByCbhInExpiredRequirement =
    isCheckrDocumentRejected &&
    updatedHcpRequirementStatus.expired.includes(CHECKR_REQUIREMENT_ID) &&
    isDefined(checkrDocument) &&
    isDefined(checkrRequirementExpiryDate) &&
    differenceInDays(parseISO(checkrRequirementExpiryDate), parseISO(checkrDocument.createdAt)) < 0;

  if (isCheckrDocumentRejectedByCbhInExpiredRequirement) {
    updatedHcpRequirementStatus.rejected.push(CHECKR_REQUIREMENT_ID);
    updatedHcpRequirementStatus.expired = updatedHcpRequirementStatus.expired.filter(
      (requirementId) => requirementId !== CHECKR_REQUIREMENT_ID
    );
  }

  const isCheckrDocumentRejectedByCbhInMissingRequirement =
    isCheckrDocumentRejected && updatedHcpRequirementStatus.missing.includes(CHECKR_REQUIREMENT_ID);

  if (isCheckrDocumentRejectedByCbhInMissingRequirement) {
    updatedHcpRequirementStatus.rejected.push(CHECKR_REQUIREMENT_ID);
    updatedHcpRequirementStatus.missing = updatedHcpRequirementStatus.missing.filter(
      (requirementId) => requirementId !== CHECKR_REQUIREMENT_ID
    );
  }

  return {
    ...updatedHcpRequirementStatus,
    completed: uniq(updatedHcpRequirementStatus.completed),
    expired: uniq(updatedHcpRequirementStatus.expired),
    missing: uniq(updatedHcpRequirementStatus.missing),
    pending: uniq(updatedHcpRequirementStatus.pending),
    rejected: uniq(updatedHcpRequirementStatus.rejected),
  };
}
